.blog-post-container
{
    background-color: var(--white-color);
    border-top: 2px solid var(--secondary-color);
    font-weight: 500;

    .post-banner
    {
        background-color: var(--secondary-color);
        color: white;
        padding: 40px;

        .post-title 
        {
            font-size: 44px;
            margin-bottom: 10px;
            font-weight: 700;
        }
    }

    .blog-post 
    {
        margin: 0px auto;
        padding: 30px 30px 30px 0;

        .post-heading
        {
            text-align: center;
            margin: 0;
            padding-bottom: 20px;
            font-size: 32px;
            border-bottom: 1px solid var(--secondary-color);
            font-weight: 700;
        }
        
        .post-body 
        {
            margin-top: 20px;

            h4
            {
                font-weight: 700;
            }

            li
            {
                margin-left: 5%;
                max-width: 600px;
            }

        }
    }

    .featured-posts
    {
        margin-top: 30px;
        margin-bottom: 20px;
        padding-left: 20px;

        .posts-title
        {
            text-align: center;
            padding-bottom: 20px;
            font-size: 32px;
            border-bottom: 1px solid var(--secondary-color);
        }

        .card:hover {
            border-color: var(--primary-color); 
            box-shadow: 2px 2px 10px var(--primary-color); 
            transform: scale(1.10); 
            transition: transform 0.3s ease;       
        }
        .card
        {
            box-shadow: 2px 2px 5px var(--black-color); 
            transition: box-shadow 0.3s ease;
            margin-top: 40px;
            border: none;
            text-decoration: none;
            transition: transform 0.3s ease;       

            .card-header
            {
                text-align: center;
                padding: 10px 10px;
                border: none;

                .blog-heading{
                    display: none;
                }
                
            }

            .card-body
            {
                overflow: hidden;
                margin-bottom: 0px;
                padding-bottom: 0px;
            }

            .card-footer
            {
                text-align: center;
                border-top: none;
                background-color: var(--white-color);
                
                .read-more-link
                {
                    border: none;
                    padding: 8px 16px;
                    border-radius: 4px;
                    cursor: pointer;
                    width: 50%;
                    text-decoration: none;
                }

                .read-more-link:hover
                {
                    text-decoration: underline;
                }
            }
        }   
    }

    .loading {
        text-align: center;
        font-size: 26px;
        margin: 50px;
    }
}