.footer 
{
    background-color: var(--black-color);
    color: var(--white-color);
    padding: 10px 0;
    line-height: 200%;
    font-weight: 600;

    .footer-block 
    {
        padding: 0 20px;
        margin: 20px 0;

        .social-icon
        {
            padding-right: 10px;
        }
    }
}

.footer .footer-title 
{
    color: var(--white-color);
}

.footer a 
{
    color: var(--white-color);
    text-decoration: none;
    font-size: 15px;
}

.footer a:hover 
{
    color: var(--primary-color);
}
