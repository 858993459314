.coming-soon-container {
    text-align: center;
    padding: 50px;
    margin-top: 100px;
  }
  
  .home-link {
    display: block;
    margin-top: 20px;
    font-weight: bold;
  }
  