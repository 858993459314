/* #region GlobalVars */

.fade-in-left
{
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.fade-in-left.is-visible 
{
  opacity: 1;
}

.container{
   
    @media (min-width: 1400px) {
        max-width: 1200px;
    }
    
}

/* #region Additional styles for the glowing effect */
.glow-on-hover {
    transition: transform 0.3s ease-in-out;
}

.glow-on-hover:hover {
transform: scale(1.05);
}

/* #endregion */

.home-container
{
    min-height: 100vh;
    font-weight: 500;

    /* #region HeroSection */

    .hero-image
    {
        background-size: cover;
        background-position: center;
        height: 50vh; 
        display: flex; 
        justify-content: center;
        align-items: center;
        opacity: 90%;

        .hero-section
        {
            justify-content: center;
            
            .hero-text
            {
                text-align: center;
                padding: 20px;
                color: black;
                background-color: rgba(255,255,255, 0.5);           
                
                h1 
                {
                    font-weight: 700;
                }
            }
        }
    }

    /* #endregion */

    /* #region SecondSection */

    .second-section
    {
        padding: 10px 0px;
        
        .section-title
        {      
            color: var(--primary-color);
            padding: 30px 0;
            text-align: center;

            .title-text
            {
                font-size: 18px;
                font-weight: 700;
                text-transform: uppercase;
            }

            .sub-heading
            {           
                color: var(--black-color);
                font-size: 30px;
                font-weight: 700;
            }
        }

        .card-link
        {
            text-decoration: none;
        }
    
        .card
        {
            width: 100%;
            border-radius: 0;
            height: 290px; 
            border: 2px solid gray;
            transition: border-color 0.3s;
            margin-bottom: 30px;
            overflow: hidden;

            .card-header
            {
                border: none;
                background-color: white;
                padding: 8px;

                .social-icon
                {
                    opacity: 1;
                    font-size: 3em;
                    margin: 12px;
                    transition: opacity 0.3s ease;
                    color: var(--primary-color);
                }

                @media(max-width: 1199px)
                {
                    .social-icon
                    {
                        font-size: 4em;
                        margin-bottom: 0px;
                    }
                }
            }

            .card-body
            {
                border: none;
                padding: 8px 0px 8px 8px;
                margin: 0 12px;
                text-overflow: ellipsis;

                .title-1
                {
                    font-size: 19px ;
                    font-weight: 500;
                    padding-top: 30%;
                    transition: transform 0.5s ease; 
                    
                    @media (max-width: 1399px) {
                        font-size: 18px;
                        padding-bottom: 10px;
                    }

                    @media (max-width: 1199px) {
                        font-size: 24px;
                        padding-top: 20%;
                    }

                    .arrow
                    {
                        color: var(--primary-color);         
                        font-size: 22px;
                        margin-top: 10px;
                    }
                    .rotate-down
                    {
                        transition: transform 0.5s ease;
                    }
                }

                .title-2
                {
                    font-size: 19px;
                    font-weight: 500;
                    padding-top: 30%;
                    transition: transform 0.5s ease; 
                    margin-bottom: 40px;

                    @media (max-width: 1199px) {
                        font-size: 24px;
                        padding-top: 20%;
                    }

                    .arrow
                    {
                        color: var(--primary-color);
                        font-size: 22px;
                        margin-top: 10px;
                    }
                    .rotate-down
                    {
                        transition: transform 0.5s ease; 
                    }
                }

                .sub-title
                {
                    opacity: 0;
                    transform: translateY(-40%); 
                    transition: opacity 0.5s ease, transform 0.5s ease;
                    font-weight: 500;
                }
            }
        }
        @media (min-width: 1181px) 
        {
            .card:hover 
            {       
                border-color: var(--primary-color); 
                box-shadow: 0 0 10px var(--primary-color); 
                transform: scale(1.10); 
                transition: transform 0.3s ease;

                .sub-title 
                {
                    opacity: 1;
                    transform: translateY(-100%);
                }

                .title-1
                {
                    transform: translateY(-90%); 
                }

                .title-2
                {
                    transform: translateY(-110%); 
                }

                .social-icon
                {
                    opacity: 0;
                }

                .rotate-down {
                    transform: rotate(90deg);
                }
            }
        }
        
    }

    /* #endregion */
    
    /* #region ThirdSection */
    
    .third-section
    {
        background-color: var(--fourth-color);
        margin: 40px 0 70px 0;

        @media (min-width: 1200px) {
            @media (max-width: 1550px)
            {
                width: 90%;
                margin-left: 150px;
                margin-right: -150px;

                .image-container
                {
                    margin-left: -10%;
                }

                .content-section
                {
                    margin-left: 150px !important;
                }

                .work-image 
                {
                    width: 150% !important;
                }
            }
        }

        @media (min-width: 1551px) 
        {            
            width: 100%; 
            margin-left: 100px;
            margin-right: -200px;

            .image-container
            {
                margin-left: -15%;
            }

            .content-section
            {
                margin-left: 150px !important;
            }

            .work-image 
            {
                width: 140% !important;
            }
        }

        .content-section
        {
            padding: 30px;
            margin-left: 0;

            .section-title
            {      
                color: var(--white-color);
                padding: 30px 0;
                
                .title-text
                {
                    font-size: 18px;
                    font-weight: 700;
                    text-transform: uppercase;
                }

                .sub-heading
                {           
                    font-size: 30px;
                    font-weight: 600;
                }
            }

            .text-paragraph
            {
                color: white;
                font-size: 18px;
                line-height: 200%;
            }

            .work-button
            {
                background-color: var(--fourth-color);
                color: var(--white-color) ;
                border: 2px solid var(--white-color);
                margin: 20px 0;
                width: 70%;
                max-width: 170px;
                float: left;
                border-radius: 0;
                font-weight: 600;
                transition: background-color 0.5s ease; 
            }
            
            .work-button:hover
            {
                background-color: var(--white-color);
                color: var(--fourth-color) ;
                border: 2px solid var(--white-color);
            }
        }

        .image-container
        {
            display: flex;
            align-items: center;
            justify-content: center; 
            
            .work-image 
            {
                width: 100%;
            }
        }

    
        @media (max-width: 767px) 
        {
            .work-image
            {
                display: none;
            }
        }
    }

    /* #endregion  */

    /* #region FourthSection */

    .fourth-section
    {
        margin: 40px 0;

        .section-title
        {
            color: var(--secondary-color);
            padding-bottom: 20px;
            padding-top: 20px;
            h2
            {
                font-size: 30px;
                font-weight: 700;
            }
        }
        
        .featured-post 
        {
            margin-bottom: 30px;
            
            h3 {
                font-size: 32px;
                margin-bottom: 10px;
            }
            
            h4 {
                font-size: 20px;
                color: black;
                margin-bottom: 20px;
                font-weight: bold;
            }  
            
            h5 {
                font-size: 20px;
                color: #888;
                margin-bottom: 20px;
            }

            .post-text-container
            {
                max-height: 32em;
                overflow-y: hidden;
            }

            p {
                font-size: 16px;
                line-height: 1.6;
            }            
        }

        .blog-button
        {
            background-color: var(--white-color);
            color: var(--secondary-color) ;
            border: 2px solid var(--secondary-color);
            margin: 20px 0;
            width: 70%;
            max-width: 170px;
            float: left;
            border-radius: 0;
            font-weight: 600;               
            transition: background-color 0.5s ease; 
        }
        
        .blog-button:hover 
        {
            background-color: var(--secondary-color);
            color: var(--white-color) ;
            border: 2px solid var(--secondary-color);
        }
    }

    /* #endregion */

}