.contact-section
{
    background-color: var(--secondary-color);
    padding: 30px 0px;
    color: var(--white-color);

    .contact-button
    {
        background-color: var(--secondary-color);
        color: var(--white-color) ;
        float: right;
        width: 70%;
        max-width: 170px;
        border: 2px solid var(--white-color);
        border-radius: 0;
        font-weight: 600;
        transition: background-color 0.5s ease; 

        @media (max-width: 767px) 
        {
            float: left;
        }
    }
    
    .contact-button:hover 
    {
        background-color: var(--white-color);
        color: var(--secondary-color) ;
        border: 2px solid var(--white-color);
    }
}