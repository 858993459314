/* ------ Global ----- */
.container 
{
    margin: 0 auto;
}

.navbar
{    
    .navbar-brand
    {
        font-size: 30px;
        color: var(--black-color);
        text-decoration: none;

        .logo-image{
            width: 50px;
            height: 50px;
            border-radius: 10%;
            margin-top: -6px;
        }
    }

    .navbar-brand:hover 
    {
        color: var(--primary-color);
    }

    .navbar-links 
    {
        margin: 0;
        padding: 0;
        list-style-type: none;
        font-size: 14px;
        text-transform: uppercase;
    }

    .navbar-links .navbar-link-buttons 
    {
        display: inline-block;
        margin-right: 20px;
        margin-top: 2px;
        color: var(--black-color);
        text-decoration: none;
        font-weight: 500;

        @media (max-width :767px) {
            font-size: 20px;
            font-weight: 600;
        }
    }

    .navbar-links .navbar-link-buttons:first-child 
    {
        margin-left: 0; 
    }
    
    .navbar-links .navbar-link-buttons:hover 
    {
        color: var(--primary-color);
    }


    .navbar-links .contact-button
    {
        color: var(--black-color) ;
        font-weight: 500;
        border: 2px solid var(--primary-color);
        transition: background-color 0.5s ease; 

        @media (max-width :767px) 
        {
            font-size: 20px;
            text-align: left;
            color: black;
            font-weight: 600;
            border: none;
        }
    }

    .navbar-links .contact-button:hover 
    {
        background-color: var(--primary-color);
        color: var(--white-color) ;
        border: 2px solid var(--primary-color);

        @media (max-width :767px) {
           border: none;
        }
    }
}