.blog-container
{
    /* #region HeroSection */
    font-weight: 500;

    .hero-image
    {
        height: 30vh;
        display: flex;
        align-items: center;
        text-align: center; 
        background-size: cover; 

        .hero-title
        {
            padding: 30px;
            color: var(--white-color);
            font-size: 50px;
        }

        .hero-title::after 
        {
            content: '';
            position: absolute;
            left: 50%;
            margin-top: 90px;
            width: 200px;
            height: 2px;
            background-color: var(--primary-color);
            transform: translateX(-50%);
        }
    }

    /* #endregion */
    
    /* #region FeaturedPosts */

    .featured-posts
    {
        margin: 20px 0;

        .card
        {
            margin-top:30px;
            box-shadow: 3px 3px 5px var(--dark-color); 
            border-radius: 10px;

            .card-header
            {
                border: none;
                
                .blog-title
                {
                    text-align: center;
                    padding: 10px 0px;
                    font-weight: 700;
                }
            }

            .card-body
            {
                overflow: hidden;
                background-color: white;
                border-radius: 10px;

                .blog-button
                {
                    text-align: center;

                    .read-button
                    {
                        background-color: var(--secondary-color);
                        color: var(--white-color) ;
                        width: 60%;
                        max-width: 170px;
                        border: 2px solid var(--secondary-color);
                        border-radius: 0;
                        font-weight: 600;
                        transition: background-color 0.5s ease; 
                    }
                    
                    .read-button:hover 
                    {
                        background-color: var(--white-color);
                        color: var(--secondary-color) ;
                        border: 2px solid var(--secondary-color);
                    }
    
                    .delete-button {
                        background-color: var(--red-color);
                        color: var(--white-color) ;
                        float: right;
                        width: 35%;
                        max-width: 170px;
                        border: 2px solid var(--red-color);
                        border-radius: 0;
                        font-weight: 600;
                        transition: background-color 0.5s ease; 

                    }
                    
                    .delete-button:hover {
                        background-color: var(--white-color);
                        color: var(--red-color);
                        border: 2px solid var(--red-color);
    
                    }
                }
            }
        }
    }

    /* #endregion */

}